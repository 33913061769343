import React from 'react';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import "./styles/findDreamCareer.scss";

const FindDreamCareer = () => {
  return (
    <section className="homepage-dream">
      <div className="dream-text mb-4">
        <h1>Find Your Dream Career Now</h1>
        <h2>Make Your Own Success Stories!</h2>
      </div>
      <Link to="/signup">
        <Button variant="warning" className="dream-button">Yes, i'm in!</Button>
      </Link>
    </section>
  )
}

export default FindDreamCareer;