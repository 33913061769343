export const navlinks = [
  {
    name: "Job Seekers",
    path: "/jobs",
  },
  {
    name: "Sign Up",
    path: "/signup",
  },
  {
    name: "Sign In",
    path: "/signin",
  },
];
