import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import bsCustomFileInput from 'bs-custom-file-input';
import { uploadFile, getUserFile } from 'firebase/fileHandler';
import { updateUserData } from 'firebase/userHandler';


export default ({ show, onHide, profileImage, setParentImg }) => {
  const allowedType = ["image/png"];
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState(profileImage);
  const [send, setSend] = useState({loading: false, state: null});
  const reader = new FileReader();

  useEffect(() => {
    setFileUrl(profileImage);
  }, [profileImage])

  reader.onloadend = () => {
    setFileUrl(reader.result);
  }

  const isFileValid = () => {
    return file.size <= 2097152 && allowedType.includes(file.type); //2MB
  }

  const handleFile = e => {
    setFile(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  }

  const submitFile = async () => {
    if (file && isFileValid() ) {
      setSend({...send, loading: true});
      const successUpload = await uploadFile(file, "profile");
      const fileDbUrl = await getUserFile("profile", "png");
      await updateUserData("imgUrl", fileDbUrl);
      setParentImg(fileDbUrl);
      setSend({state: successUpload, loading: false});
    }
  }

  const handleClose = () => {
    onHide();
    setFile();
    setSend({loading: false, state: null});
  }

  useEffect(() =>{
    bsCustomFileInput.init();
  });

  return (
    <Modal 
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title className="font-weight-bold">Upload Profile Image</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <Container className="d-flex mb-4 justify-content-center align-items-center" style={{ maxHeight: '300px', maxWidth: '300px' }}>
              <Image fluid src={fileUrl} roundedCircle />
            </Container>
            <Form>
              <Form.File 
                id="custom-file"
                label='Choose file'
                custom
                onChange={e => handleFile(e)}
                accept={allowedType.join(", ")}
                className="text-truncate"
              >
              </Form.File>
              <Form.Text className={`text-${file ? (isFileValid() ? "muted" : "danger") : "muted"}`}>
                Use 1:1 Photo, Maximum file size: 2MB
              </Form.Text>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {
              send.state ? 
              <>
                <p className="text-success">Success</p>
                <Button onClick={handleClose}>Close</Button>
              </>
              :
              <>
              <Button variant="danger" onClick={handleClose}>Cancel</Button>
              <Button onClick={submitFile} disabled={send.loading}>
              {send.loading ? 
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : null}
              {send.loading ? " Loading" : "Submit"}
            </Button>
            </>}
          </Modal.Footer>
        
      </Modal>
  );
}