import React, { useContext, useState } from 'react'
import {
  Jumbotron,
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
} from 'react-bootstrap'
// import { FaFacebookSquare } from 'react-icons/fa'
// import { FcGoogle } from 'react-icons/fc'
import Layout from 'components/Layout'
import { firebaseAuth } from 'provider/AuthProvider'
import { Link, Redirect } from 'react-router-dom'
import {
  validateEmail,
  validatePhoneNumber,
  validatePassword,
} from 'utils/utils'

export default ({ history }) => {
  const { handleSignup, inputs, setInputs, errors, token } = useContext(
    firebaseAuth,
  )
  const [validEmail, setValidEmail] = useState(true)
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [validPassword, setValidPassword] = useState(true)
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validEmail && validPassword && validPhoneNumber) {
      await handleSignup()
      if (token) {
        history.push('/')
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }

  return token ? (
    <Redirect push to="/" />
  ) : (
    <Layout page="Sign Up">
      <Jumbotron fluid className="my-0 background-signup">
        <Container fluid className="h-100">
          <Row>
            <Col
              xs={12}
              lg={8}
              className="text-white text-center d-flex justify-content-center align-items-center"
            >
              <div className="container">
                <h1 className="font-weight-bold">Get Your Dream Career Now</h1>
                <p>Join millions success stories</p>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <Card className="shadow card-signup">
                <Card.Body>
                  <Card.Title as="h2" className="text-center font-weight-bold">
                    Sign Up
                  </Card.Title>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter phone number"
                        name="phonenumber"
                        value={inputs.phonenumber}
                        onChange={handleChange}
                        onBlur={() =>
                          setValidPhoneNumber(
                            validatePhoneNumber(inputs.phonenumber),
                          )
                        }
                      />
                      <Form.Text className="text-muted">
                        Ex. 08xxxxxxxxxx
                      </Form.Text>
                      {inputs.phonenumber && !validPhoneNumber ? (
                        <Form.Text className="text-danger font-weight-bold">
                          Please provide a valid phone number
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                        onBlur={() =>
                          setValidEmail(validateEmail(inputs.email))
                        }
                      />
                      {inputs.email && !validEmail ? (
                        <Form.Text className="text-danger font-weight-bold">
                          Please provide a valid email address
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={inputs.password}
                        onChange={handleChange}
                        onBlur={() =>
                          setValidPassword(validatePassword(inputs.password))
                        }
                      />
                      <Form.Text
                        className={`${
                          inputs.password && !validPassword
                            ? 'text-danger'
                            : 'text-muted'
                        }`}
                      >
                        Please provide a password between 6 to 20 characters
                        which contain at least one numeric digit, one uppercase
                        and one lowercase letter
                      </Form.Text>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group> */}
                    <Button variant="primary btn-block my-4" type="submit">
                      Sign Up
                    </Button>
                    {errors.length > 0
                      ? errors.map((error) => (
                          <p key={error} style={{ color: 'red' }}>
                            {error}
                          </p>
                        ))
                      : null}
                    <hr />
                  </Form>
                  {/* <Button variant="facebook btn-block mb-3 mt-4">
                    <div className="d-flex align-items-center justify-content-center">
                      <FaFacebookSquare size={22} className="mr-3"/> Sign Up with Facebook
                    </div>
                  </Button> */}
                  {/* <Button variant="outline-dark btn-block bordered my-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <FcGoogle size={22} className="mr-3"/> Sign Up with Google
                    </div>
                  </Button> */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <p>
                      Already have an account?{' '}
                      <Link to="/signin" className="text-primary">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </Layout>
  )
}
