import React from 'react';
import "./styles/whyWithUs.scss";

const WhyWithUs = () => {
  return (
    <section className="homepage-whywithus p-5">
      <div className="whywithus-title mb-4">
        <h3>Why With Us</h3>
      </div>
      <div className="whywithus-cards">
        <div className="whywithus-card">
          <img src={require("../../assets/page_homepage/s_whyWithUs/professional.png")} alt=""/>
          <h4>Professional Identity</h4>
          {/* <p>Build professional identity online and stay connected with opportunities</p> */}
        </div>
        <div className="whywithus-card">
          <img src={require("../../assets/page_homepage/s_whyWithUs/personalization.png")} alt=""/>
          <h4>Personalization</h4>
          {/* <p>Build professional identity online and stay connected with opportunities</p> */}
        </div>
        <div className="whywithus-card">
          <img src={require("../../assets/page_homepage/s_whyWithUs/trusted.png")} alt=""/>
          <h4>Trusted Job Ads</h4>
          {/* <p>Build professional identity online and stay connected with opportunities</p> */}
        </div>
      </div>
    </section>
  )
}

export default WhyWithUs;