// import Search from 'components/Search';
import React from 'react';
import "./styles/hero.scss";

const Hero = () => {
  // const [text, setText] = useState("");

  return (
    <section className="homepage-hero p-5">
      <div className="hero-text mb-4">
        <h1>Start Your Career in Energy & Sustainable Industry</h1>
        <h2>Discover more than just a workplace</h2>
      </div>
      {/* <div className="hero-search">
        <Search text={text} setText={setText}/>
      </div> */}
    </section>
  )
}

export default Hero;