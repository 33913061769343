import React, { useContext } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { firebaseAuth } from "provider/AuthProvider";

const Unverified = ({ show, resendHandler, refreshHandler }) => {
  const { handleSignout } = useContext(firebaseAuth);
  return (
    <Modal show={show} backdrop="static" centered>
      <Modal.Header className="bg-primary text-white">
        <Modal.Title>Important !!!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        A verification link was sent to your email.
        <br />
        Please verified your account in order to proceed
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => {
            refreshHandler()
          }}
        >
          Refresh
        </Button>
        <Button 
          variant="danger"
          onClick={() => handleSignout()}
        >
          Sign Out
        </Button>
        <Button variant="primary" onClick={resendHandler}>
          Resend verification link
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Unverified
