import React from 'react'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { MdEdit } from 'react-icons/md'
import { MdAdd } from 'react-icons/md'

export default ({ title, children, isEditedMode, setIsEdit }) => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center h5 font-weight-bold">
          {title}
          {!isEditedMode && (
            <Button className="btn-white rounded-circle" onClick={setIsEdit}>
              <MdEdit className="text-primary" />
            </Button>
          )}
        </Card.Header>
        <Card.Body className="bg-secondary">{children}</Card.Body>
      </Card>
    </Container>
  )
}

export const EditCommandBtn = ({ setShowModal, setEditMode, type }) => {
  return (
    <>
      <Row>
        <Button
          className="w-100"
          onClick={() => {
            setShowModal({ val: true, type: type })
          }}
        >
          Add New
          <span className="mx-2">
            <MdAdd />
          </span>
        </Button>
      </Row>
      <Row className="float-right my-2 py-2">
        <Button variant="danger" onClick={setEditMode}>
          Exit
        </Button>
      </Row>
    </>
  )
}
