import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
// import { SectionAdder } from 'components/Profile'
import Uploader from 'components/Uploader'
import profilePlaceholder from 'assets/general_placeholders/anon_user.jpg'
import backgroundPlaceholder from 'assets/general_placeholders/default_background.jpg'
import EditHighlight from './EditHighlight';

export default ({
  displayName,
  profileImage,
  backgroundUrl,
  highlightText,
  locationText,
  cvUrl,
  editMode,
  setEditMode
}) => {
  const [editProfileImg, setEditProfileImg] = useState(false);
  const [tempProfileUrl, setTempProfileUrl] = useState(profileImage);

  useEffect(() => {
    setTempProfileUrl(profileImage);
  }, [profileImage]);

  return (
    <Container className="my-4">
      <Card className="pb-4 mb-4 bg-secondary">
        <img
          align="left"
          className="image-profile-background rounded-top"
          src={backgroundUrl ? backgroundUrl : backgroundPlaceholder}
          alt="Background profile"
        />
        <Row>
          <Col xs={12} lg={3}>
            <div className="d-flex justify-content-center">
              <img
                align="left"
                className="image-profile rounded-circle"
                src={tempProfileUrl ? tempProfileUrl : profilePlaceholder}
                alt="Profile"
                style={{ cursor: editMode ? "pointer" : "default" }}
                onClick={editMode ? () => setEditProfileImg(true) : null}
              />
              <Uploader.ProfileImg 
                show={editProfileImg} 
                onHide={() => setEditProfileImg(false)} 
                profileImage={profileImage}
                setParentImg={setTempProfileUrl}
              />
            </div>
            <div className="ml-md-5 d-flex justify-content-center">
              {editMode ? <small className="mr-2 text-primary">Click profile image to edit</small> : null}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="profile-text">
              {
                editMode ? 
                <EditHighlight 
                  displayName={displayName}
                  highlightText={highlightText}
                  locationText={locationText}
                  backgroundUrl={backgroundUrl}
                  setEditMode={setEditMode}
                />
                :
                <>
                <h1 className="font-weight-bold">{displayName}</h1>
                <h5>{highlightText}</h5>
                <p>{locationText}</p>
                {cvUrl ? (
                  <p>
                    <a href={cvUrl} target="_blank" rel="noopener noreferrer">
                      Curriculum Vitae
                    </a>
                  </p>
                ) : null}
                </>
              }
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="mt-2 mr-4 text-center float-right">
            {editMode  ? 
              null
              :
              <Button disabled={typeof displayName !== 'string'} variant="primary" className="mt-2 ml-2 float-lg-right" onClick={() => setEditMode(true)}>
              Edit
              </Button>}
              {/* <SectionAdder /> */}
              <Uploader.CV disabled={editMode || typeof displayName !== 'string' } />
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}
