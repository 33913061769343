import { addNewExp } from 'firebase/userHandler'
import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

const AddNewExperience = ({ showModal, hideModal, addNewExpToLocalState }) => {
  const initialState = {
    fromDate: '',
    toDate: '',
    company: '',
    type: 'Full-Time',
    loc: '',
    desc: '',
  }

  const [state, setState] = useState(initialState)
  const { fromDate, toDate, type, company, loc, desc } = state

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const formData = [
    {
      type: 'date',
      label: 'Start Working Experience',
      placeholder: 'From',
      name: 'fromDate',
      value: fromDate,
    },
    {
      type: 'date',
      label: 'End Working Experience',
      placeholder: 'To',
      name: 'toDate',
      value: toDate,
    },
    {
      type: 'select',
      label: 'Job Type',
      options: [
        {
          value: 'Full-Time',
          label: 'Full Time',
        },
        {
          value: 'Part-Time',
          label: 'Part Time',
        },
      ],
    },
    {
      type: 'text',
      label: 'Company',
      placeholder: '@ex:Google',
      name: 'company',
      value: company,
    },
    {
      type: 'text',
      label: 'Location',
      placeholder: '@ex:Bandung, Indonesia',
      name: 'loc',
      value: loc,
    },
  ]
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addNewExp(state)
      addNewExpToLocalState(state)
    } catch (err) {
      alert('Error')
    } finally {
      hideModal()
      setState(initialState)
    }
  }
  return (
    <Modal show={showModal} centered backdrop="static" size="lg">
      <Modal.Header className="bg-primary text-white">
        <Modal.Title className="font-weight-bold">
          Add New Work Experience
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {formData.map((item) =>
            item.type !== 'select' ? (
              <Form.Group key={item.label}>
                <Form.Label>{item.label}</Form.Label>
                <Form.Control
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={item.value}
                  onChange={onChangeHandler}
                  required
                />
              </Form.Group>
            ) : (
              <Form.Group key={item.label}>
                <Form.Label>{item.label}</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={type}
                  onChange={onChangeHandler}
                >
                  {item.options.map((optionItem) => (
                    <option value={optionItem.value} key={optionItem.label}>
                      {optionItem.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ),
          )}
          <Form.Group>
            <Form.Label>Job Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              onChange={onChangeHandler}
              value={desc}
              type="text"
              name="desc"
              placeholder="Job description"
              style={{ width: '100%' }}
              required
            />
          </Form.Group>
          <div className="float-right">
            <Button onClick={hideModal} className="btn-md btn-danger">
              Cancel
            </Button>
            <Button className="btn-md btn-primary ml-2" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  )
}

export default AddNewExperience
