import { useState, useEffect } from 'react'
import { BREAKPOINT_WIDTH } from '../utils/constant'

export const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [width])
  return width
}

export const useIsLargeScreen = () => {
  const width = useWidth();
  return width > BREAKPOINT_WIDTH;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validatePhoneNumber = (phoneNumber) => {
  const re =/^08\d{8,10}$/;
  return re.test(String(phoneNumber));
}

export const validatePassword = (password) => {
  const re =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return re.test(String(password));
}

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return { value, setValue, onChange: handleChange }
}

export const changeToUrl = str => {
  return str
    .replace(/:|\?|"|'/g, '')
    .replace(/ /g, '_')
    .replace(/-|\./g, '')
    .toLowerCase();
};
