import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import bsCustomFileInput from 'bs-custom-file-input';
import { uploadFile, getUserFile } from 'firebase/fileHandler';
import { updateUserData } from 'firebase/userHandler';

export default ({ disabled }) => {
  const allowedType = ["application/pdf"];
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [send, setSend] = useState({loading: false, state: null});
 
  const handleClose = () => {
    setShow(false);
    setFile();
    setSend({loading: false, state: null});
  };
  const handleShow = () => setShow(true);

  const isFileValid = () => {
    return file.size <= 2097152 && allowedType.includes(file.type); //2MB
  }

  const submitFile = async () => {
    if (file && isFileValid() ) {
      setSend({...send, loading: true});
      const successUpload = await uploadFile(file, "cv"); //upload CV
      const cvUrl = await getUserFile('cv', 'pdf');
      updateUserData("cvUrl", cvUrl);
      setSend({state: successUpload, loading: false});
    }
  }

  useEffect(() =>{
    bsCustomFileInput.init();
  });

  return (
    <>
      <Button variant="primary" className="mt-2 ml-2 float-lg-right" onClick={handleShow} disabled={disabled}>
        Upload CV
      </Button>

      <Modal 
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title className="font-weight-bold">Your <i>Curriculum Vitae</i></Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.File 
                id="custom-file"
                label='Choose file'
                custom
                onChange={e => setFile(e.target.files[0])}
                accept={allowedType.join(", ")}
                className="text-truncate"
              >
              </Form.File>
              <Form.Text className={`text-${file ? (isFileValid() ? "muted" : "danger") : "muted"}`}>
                Maximum file size: 2MB
              </Form.Text>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {
              send.state ? 
              <>
                <p className="text-success">Success</p>
                <Button onClick={handleClose}>Close</Button>
              </>
              :
              <>
              <Button variant="danger" onClick={handleClose}>Cancel</Button>
              <Button onClick={submitFile} disabled={send.loading}>
              {send.loading ? 
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : null}
              {send.loading ? " Loading" : "Submit"}
            </Button>
            </>}
          </Modal.Footer>
        
      </Modal>
    </>
  );
}