import Layout from "components/Layout";
import React from "react";
import HomepageComponents from "components/LandingComponents";

export default () => {
  return (
    <Layout page="Home">
      <HomepageComponents.Hero/>
      <HomepageComponents.Collaboration/>
      <HomepageComponents.TopEmployers/>
      {/* <HomepageComponents.Explore/> */}
      {/* <HomepageComponents.Feature/> */}
      <HomepageComponents.WhyWithUs/>
      <HomepageComponents.FindDreamCareer/>
    </Layout>
  );
};
