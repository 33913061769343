import React from 'react';
import { FaBriefcase, FaDollarSign, FaMapMarkerAlt } from 'react-icons/fa';
import "./styles/feature.scss";

const Feature = () => {
  const cardData = [
    {
      imgurl: "https://images.unsplash.com/photo-1601908302911-2c5eb614c17d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
      title: "Awesome Job Title",
      subtitle: "Society of Renewable Energy",
      location: "Jl. Badak no.19 Bandung",
      entryLevel: "Entry Level",
      salary: "Undisclosed"
    },
    {
      imgurl: "https://images.unsplash.com/photo-1601908302911-2c5eb614c17d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
      title: "Another Awesome Job Title",
      subtitle: "Society of Renewable Energy",
      location: "Jl. Badak no.19 Bandung",
      entryLevel: "Entry Level",
      salary: "Undisclosed"
    },
    {
      imgurl: "https://images.unsplash.com/photo-1601908302911-2c5eb614c17d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
      title: "Yet Another Awesome Job Title",
      subtitle: "Society of Renewable Energy",
      location: "Jl. Badak no.19 Bandung",
      entryLevel: "Entry Level",
      salary: "Undisclosed"
    },
  ]

  return (
    <section className="homepage-feature p-5">
      <div className="feature-title">
        <h3>Featured</h3>
        <p>Handpicked by our curator</p>
      </div>
      <div className="feature-cards">

        {cardData.map((item, key) => {
          return (
            <div className="feature-card" key={key}>
              <div 
                className="card-img-header" 
                style={{
                  "background": `url('${item.imgurl}')`
                }}
              />
              <div className="card-body">
                <div className="card-title">
                  <span className="card-title-text">{item.title}</span>
                  <span className="card-subtitle-text">{item.subtitle}</span>
                </div>
                <div className="card-points">
                  <div className="card-point-item">
                    <span className="card-icon mr-2"><FaMapMarkerAlt/></span>
                    <p>{item.location}</p>
                  </div>
                  <div className="card-point-item">
                    <span className="card-icon mr-2"><FaBriefcase/></span>
                    <p>{item.entryLevel}</p>
                  </div>
                  <div className="card-point-item">
                    <span className="card-icon mr-2"><FaDollarSign/></span>
                    <p>{item.salary}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

      </div>
    </section>
  )
}

export default Feature;