import React, { useContext, useState, useEffect } from "react";
import { Card, Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { BiTime } from "react-icons/bi";
import { MdWork } from "react-icons/md";
import { GoLocation } from "react-icons/go";
// import { AiOutlineHeart } from "react-icons/ai";
// import { FcLike } from "react-icons/fc";
import ApplyModal from "components/Jobs/Modal";
import { getJobImg } from "firebase/jobsHandler";
import { getUserData } from "firebase/userHandler";
import { firebaseAuth } from "provider/AuthProvider";

export const JobCard = ({ data, isLike, closed }) => {
  const { token } = useContext(firebaseAuth);
  const [jobImg, setJobImg] = useState();
  const [canApply, setCanApply] = useState();
  const [applyDisabled, setApplyDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getJobImg(data.logo ? data.logo : data.company);
      setJobImg(response);
    }
    fetchData();
  }, [data])

  useEffect(() => {
    setCanApplyJobs();
  })

  const setCanApplyJobs = async () => {
    if (token) {
      const userData = await getUserData();
      setCanApply(userData.cvUrl && userData.workExperience && userData.skills ? true : false);
      setApplyDisabled(userData.appliedJobId ? userData.appliedJobId.includes(data.id) : false); //disable Apply button if user ever submit
    }
  }

  const details = [
    {
      logo: <BiTime />,
      value: data.type,
    },
    {
      logo: <MdWork />,
      value: data.level,
    },
    {
      logo: <GoLocation />,
      value: data.location,
    },
  ];

  return (
    <Card className="d-flex flex-row position-relative bg-secondary align-items-center">
      <Row>
        <Col md={2} sm={12} className="d-flex align-items-center justify-content-center">
          <Container className="card-container d-flex align-items-center justify-content-center m-0 pr-0">
          {jobImg ?
            <img
              src={jobImg}
              alt="company-logo"
              className="img-fluid rounded m-md-3 my-3 mr-3"
            />
            :
              <Spinner animation="grow" />
            }
            </Container>
        </Col>
        <Col md={9} sm={12} className="pl-md-0">
          {/* <div className="wishlist-btn">
            <span role="btn">{isLike ? <FcLike /> : <AiOutlineHeart />}</span>
          </div> */}
          <Card.Body className="mx-md-3 mx-sm-0 pl-md-0">
            <Card.Title className="my-0">
              <h4 className="font-weight-bold">{data.title}</h4>
            </Card.Title>
            <p>{data.company}</p>
            <div className="d-flex">
              <div className="mr-auto">
                <ul className="list-group">
                  {details.map((detail, idx) => (
                    <li
                      key={idx}
                      className="list-group-item"
                      style={{ padding: "0", border: "none", backgroundColor: "inherit" }}
                    >
                      <span className="mx-2">{detail.logo}</span>
                      {detail.value}
                    </li>
                  ))}
                </ul>
              </div>
              
            </div>
          </Card.Body>
        </Col>
        <Col md={1} xs={12} className="d-flex flex-column justify-content-end align-items-end">
          <div className="mb-4 mr-4">
            {closed ?
              <Button variant="warning" disabled>Closed</Button>
              :
              <ApplyModal data={data} canApply={canApply} applyDisabled={applyDisabled} />
            }
            
            {/* <button className="btn cta message-btn">Message</button> */}
          </div>
        </Col>
      </Row>
    </Card>
  );
};
