import Hero from "./_hero";
import Collaboration from "./_collaboration";
import TopEmployers from "./_topEmployers";
import Explore from "./_explore";
import Feature from "./_feature";
import WhyWithUs from "./_whyWithUs";
import FindDreamCareer from "./_findDreamCareer";

const HomepageComponents = {
  Hero,
  Collaboration,
  TopEmployers,
  Explore,
  Feature,
  WhyWithUs,
  FindDreamCareer
}

export default HomepageComponents;