import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { GoCheck } from "react-icons/go";
import { useFormInput } from "utils/utils";
import { firebaseAuth } from "provider/AuthProvider";
import { sendApplication } from 'firebase/jobsHandler';

export default ({ data, canApply, applyDisabled }) => {
  const { token } = useContext(firebaseAuth);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [send, setSend] = useState({loading: false, state: null});
  const motivation = useFormInput("");
 
  const handleClose = () => {
    setShow(false);
    setSend({loading: false, state: null});
  };
  const handleShow = () => {
    token ? setShow(true) : history.push(`/signin`);
  };

  const submit = (e) => {
    e.preventDefault();
    setSend({...send, loading: true});
    const successUpload = sendApplication(data.id, data.title, data.company, motivation.value, data.type);
    setSend({state: successUpload, loading: false});
  }

  const CannotApply = () => (
    <>
      <Modal.Body>
        <h5 className="font-weight-bold">Cannot Apply to This Job!</h5>
        <p>Please add your work experiences, education, skills, and upload your CV in Profile page.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </>
  )
        
  return (
    <>
      <Button disabled={applyDisabled} variant="warning" onClick={handleShow}><GoCheck/>{" "}Apply now</Button>

      <Modal 
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title className="font-weight-bold">Application Form</Modal.Title>
        </Modal.Header>
        { canApply ?
          <Form onSubmit={submit}>
            <Modal.Body>
              <h5 className="font-weight-bold">{data.title}</h5>
              <h6>Company: <span className="font-weight-bold">{data.company}</span></h6>
              <h6>Qualification:</h6>
              <ul>
                {data.qualification.map(val => (
                  <React.Fragment key={val}>
                  {"- "+val}<br/>
                  </React.Fragment>
                ))}
              </ul>
            
            
              <Form.Label as="h6">
                Motivation Letter
              </Form.Label>
              <Form.Control
                as="textarea"
                value={motivation.value}
                type="text"
                rows="10"
                onChange={motivation.onChange}
                required
              />
            </Modal.Body>
            <Modal.Footer>
              {
                send.state ? 
                <>
                  <p className="text-success">Success</p>
                  <Button onClick={handleClose}>Close</Button>
                </>
                :
                <>
                <Button variant="danger" onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={send.loading}>
                {send.loading ? 
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : null}
                {send.loading ? " Loading" : "Submit"}
              </Button>
              </>}
            </Modal.Footer>
          </Form>
          : <CannotApply />
        }
        
      </Modal>
    </>
  );
}