import React from "react";
import ReactDOM from "react-dom";
import AuthProvider from "provider/AuthProvider";
import Router from "router/Router";
import App from "./App";
import ScrollToTop from 'utils/ScrollToTop';

// Importing Sass with Bootstrap CSS
import "styles/App.scss";
// import Navigation from "components/Navigation";

ReactDOM.render(
  <Router>
    <AuthProvider>
      <ScrollToTop/>
      <App />
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);
