import { addNewEdu } from 'firebase/userHandler'
import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

const AddNewEdu = ({ showModal, hideModal, addNewEduToLocalState }) => {
  const initialState = {
    fromDate: '',
    toDate: '',
    school: '',
    desc: '',
  }

  const [state, setState] = useState(initialState)
  const { fromDate, toDate, school, desc } = state

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const formData = [
    {
      type: 'date',
      label: 'Start Date',
      placeholder: 'From',
      name: 'fromDate',
      value: fromDate,
    },
    {
      type: 'date',
      label: 'End Date',
      placeholder: 'To',
      name: 'toDate',
      value: toDate,
    },
    {
      type: 'text',
      label: 'School',
      placeholder: '@ex: ITB',
      name: 'school',
      value: school,
    },
  ]
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addNewEdu(state)
      addNewEduToLocalState(state)
    } catch (err) {
      alert('Error')
    } finally {
      hideModal()
      setState(initialState)
    }
  }

  return (
    <Modal show={showModal} centered backdrop="static" size="lg">
      <Modal.Header className="bg-primary text-white">
        <Modal.Title className="font-weight-bold">
          Add New Education
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {formData.map((item) => (
            <Form.Group key={item.label}>
              <Form.Label>{item.label}</Form.Label>
              <Form.Control
                type={item.type}
                placeholder={item.placeholder}
                name={item.name}
                value={item.value}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          ))}
          <Form.Group>
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              onChange={onChangeHandler}
              value={desc}
              type="text"
              name="desc"
              placeholder="Short description"
              required
            />
          </Form.Group>
          <div className="float-right">
            <Button onClick={hideModal} className="btn-md btn-danger">
              Cancel
            </Button>
            <Button className="btn-md btn-primary ml-2" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddNewEdu
