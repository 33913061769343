import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.scss";

import { FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";

const SREFooter = () => {
  return (
    <>
      <Container fluid className="sre-footer py-3 px-md-5 px-3">
        <Row>
          <Col xs={12} className="py-2">
            <div className="footer-social">
              <div className="footer-social-links">
                <a href="https://www.linkedin.com/company/society-of-renewable-energy/" target="_blank" className="text-white"><FaLinkedinIn size={"2rem"} /></a>
                <a href="https://www.instagram.com/talentpool_sreecadin/" target="_blank" className="text-white"><FaInstagram size={"2rem"} /></a>
                <a href="https://srenasional.com/" target="_blank" className="text-white"><BiWorld size={"2rem"} /></a>
              </div>
              <h3 className="mt-4 font-weight-bold">TALENT POOL</h3>
              <p>Collaboration by Society of Renewable Energy and ECADIN</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SREFooter;
