import React from 'react'
import { MdEdit } from 'react-icons/md'
import { Row, Col, Button } from 'react-bootstrap'

const WorkExperience = ({ data, setEdit, isExpEditMode }) => {
  return (
    <>
      <Row>
        {isExpEditMode && (
          <Col xs={12} md={12}>
            <Button
              className="btn-white rounded-circle float-right"
              onClick={setEdit}
            >
              <MdEdit className="text-primary" />
            </Button>
          </Col>
        )}
        <Col xs={12} md={2}>
          {/* Todo , use React Moment to format date*/}
          <p>{data.fromDate}<br/>{' - ' + data.toDate} </p>
        </Col>
        <Col xs={12} md={10}>
          <div className="d-flex justify-content-between">
            <h5 className="font-weight-bold">{data.company}</h5>
            <p>{data.type}</p>
          </div>
          <p>{data.loc}<br/>{data.desc}</p>
        </Col>
      </Row>
      <hr></hr>
    </>
  )
}

export default WorkExperience
