import React, { useState } from 'react'
import { authMethods } from '../firebase/authMethods'

export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
  const initState = { email: '', password: '', name: '', phonenumber: '' }
  const [inputs, setInputs] = useState(initState)
  const [errors, setErrors] = useState([])
  const [token, setToken] = useState(window.localStorage.token || null)

  const handleSignup = () => {
    authMethods.signup(
      inputs.email,
      inputs.password,
      inputs.name,
      inputs.phonenumber,
      setErrors,
      setToken,
    )
  }
  const handleSignin = () => {
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken)
  }

  const handleSignout = () => {
    authMethods.signout(setErrors, setToken)
  }

  const handleUpdateToken = () => {
    authMethods.updateToken(setToken)
  }

  // const handleResendEmailVerification = () => {
  //   authMethods.resendEmailVerification()
  // }

  return (
    <firebaseAuth.Provider
      value={{
        handleSignup,
        handleSignin,
        token,
        inputs,
        setInputs,
        errors,
        handleSignout,
        handleUpdateToken,
        // handleResendEmailVerification,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  )
}

export default AuthProvider
