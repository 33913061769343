import React from 'react';
import Image from 'react-bootstrap/Image';
import "./styles/topEmployers.scss";

const TopEmployers = () => {
  return (
    <section className="homepage-employers p-5">
      <div className="employers-title">
        <h3>Our Sponsors</h3>
        {/* <p>Make your dream career come true</p> */}
      </div>
      <Image fluid style={{ height: 'auto', width: '500px' }} src={require("../../assets/page_homepage/s_dreamCompanies/Sponsor.png")} alt=""/>
    </section>
  )
}

export default TopEmployers;