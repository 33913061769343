import firebaseconfig from './firebaseIndex'
import firebase from 'firebase'

export const authMethods = {
  signup: (email, password, displayName, phoneNumber, setErrors, setToken) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      //make res asynchonous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b
        //set token to localStorage
        res.user.updateProfile({ displayName })
        await localStorage.setItem('token', token)
        setToken(token)
        firebase.database().ref(`users/${res.user.uid}`)
        .set({
          phoneNumber,
          about: 'Tell me about yourself...',
          highlight: 'Your highlighted job',
          location: 'Your location',
        })
        res.user.sendEmailVerification()
      })
      .catch((err) => {
        setErrors((prev) => Array.from(new Set([...prev, err.message])))
      })
  },

  signin: (email, password, setErrors, setToken) => {
    //change from create users to...
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      //everything is almost exactly the same as the function above
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b
        //set token to localStorage
        await localStorage.setItem('token', token)
        setToken(window.localStorage.token)
      })
      .catch((err) => {
        setErrors((prev) => Array.from(new Set([...prev, err.message])))
      })
  },

  signout: (setErrors, setToken) => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //remove the token
        localStorage.removeItem('token')
        //set the token back to original state
        setToken(null)
        window.location = window.location.origin;
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => Array.from(new Set([...prev, err.message])))
        //whether firebase does the trick or not i want my user to do there thing.
        localStorage.removeItem('token')
        setToken(null)
        console.error(err.message)
        window.location = window.location.origin;
      })
  },

  resendEmailVerification: () => {
    let user = firebase.auth().currentUser
    user.sendEmailVerification()
  },

  updateToken: async (setToken) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {localStorage.setItem('token', idToken)})
      .catch(err => {
        console.log(err)
      })
    await 
    setToken(window.localStorage.getItem('token'))
  },
}
