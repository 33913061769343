import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { ImBin } from 'react-icons/im'

const Skills = ({ skills, exitEdit, deleteSkillHandler }) => {
  return (
    <>
      <ListGroup>
        {skills &&
          skills.data &&
          skills.data.map((skill, idx) => (
            <ListGroup.Item key={idx}>
              {skill}
              {skills.isEdit && (
                <Button
                  className="btn-sm btn-danger float-right"
                  onClick={() => deleteSkillHandler(idx)}
                >
                  <ImBin />
                </Button>
              )}
            </ListGroup.Item>
          ))}
      </ListGroup>
      {skills.isEdit && (
        <Button onClick={exitEdit} className="btn btn-danger float-right mt-2">
          Exit
        </Button>
      )}
    </>
  )
}

export default Skills
