import React from 'react'
import { Switch, Route } from 'react-router-dom'
import _404 from 'page/404.js'
import Landing from 'page/Landing'
import Job from 'page/Job'

const UnauthorizedRoute = ({ match }) => (
  <Switch>
    <Route exact path="/" component={Landing} />
    <Route exact path="/jobs" component={Job} />
    <Route path={match.url} component={_404} />
  </Switch>
)

export default UnauthorizedRoute
