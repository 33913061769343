import React, { useEffect } from 'react'
import './Nav.scss'

export default ({ handleMenu, isMenuOpen, defaultOpen }) => {
  useEffect(() => {
    const hamburger = document.getElementById('hamburger')
    if (hamburger) hamburger.classList.toggle('closed')
  }, [isMenuOpen])
  return (
    <div
      id="hamburger"
      className={defaultOpen ? '' : 'closed'}
      role="button"
      onClick={handleMenu}
    >
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
  )
}
