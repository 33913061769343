import React from 'react';
import "./styles/collaboration.scss";
import img_sre from "../../assets/page_homepage/s_collaborationBy/sre.png";
import img_ecadin from "../../assets/page_homepage/s_collaborationBy/ecadin.png";

const Collaboration = () => {
  return (
    <section className="homepage-collaboration p-5">
      <p>Collaboration By</p>
      <div className="collaboration-img-container">
        <img src={img_sre} alt=""/>
        <h1 className="mx-4">x</h1>
        <img src={img_ecadin} alt=""/>
      </div>
    </section>
  )
}

export default Collaboration;