import { updateEdu } from 'firebase/userHandler'
import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

const EduEdit = ({
  showModal,
  hideModal,
  editingEdu: { data, idx },
  updateEduInLocalState,
}) => {
  const [state, setState] = useState(data)
  const { fromDate, toDate, school, desc } = state

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const formData = [
    {
      type: 'date',
      label: 'Start Working Experience',
      placeholder: 'From',
      name: 'fromDate',
      value: fromDate,
    },
    {
      type: 'date',
      label: 'End Working Experience',
      placeholder: 'To',
      name: 'toDate',
      value: toDate,
    },
    {
      type: 'text',
      label: 'school',
      placeholder: '@ex:Google',
      name: 'school',
      value: school,
    },
  ]
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await updateEdu(idx + 1, state)
      updateEduInLocalState(idx, state)
    } catch (err) {
      alert('Error')
    } finally {
      hideModal()
      setState(data)
    }
  }
  return (
    <Modal show={showModal} backdrop="static">
      <Modal.Header className="bg-primary text-white">
        <Modal.Title>Edit Education</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {formData.map((item) => (
            <Form.Group key={item.label}>
              <Form.Label>{item.label}</Form.Label>
              <Form.Control
                type={item.type}
                placeholder={item.placeholder}
                // name={item.name}
                value={item.value}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          ))}
          <Form.Group>
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="10"
              onChange={onChangeHandler}
              value={desc}
              type="text"
              name="desc"
              placeholder="short description"
              style={{ width: '100%' }}
              required
            />
          </Form.Group>
          <div className="float-right">
            <Button
              variant="danger"
              onClick={hideModal}
              className="btn-md btn-danger"
            >
              Cancel
            </Button>
            <Button className="btn-md btn-primary ml-2" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer> */}

      {/* </Modal.Footer> */}
    </Modal>
  )
}

export default EduEdit
