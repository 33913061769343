import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Layout from "components/Layout";
import svg from "assets/general_placeholders/404_page_not_found_.svg";

export default () => {
  return (
    <Layout page="Not Found">
      <Container fluid style={{ minHeight: "75vh" }} className="d-flex justify-content-center align-items-center">
        <Image fluid style={{ minHeight: "35vh" }} src={svg} alt="Not Found"/>
      </Container>
    </Layout>
  );
};
