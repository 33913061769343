import React, { useState, useEffect, useContext } from 'react'
import { Container, ListGroup, Button } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Layout from 'components/Layout'
import { Highlight, Section } from 'components/Profile'
import { getUserData, updateUserData, removeSkills } from 'firebase/userHandler'
import { getUserFile } from 'firebase/fileHandler'
import AboutEditForm from 'components/Forms/EditForm'
import WorkExperienceEditForm from 'components/Forms/WorkExperienceEdit'
import AddNewExperienceForm from 'components/Forms/AddNewExperience'
import WorkExperience from 'components/Forms/WorkExperience'
import { EditCommandBtn } from 'components/Profile/Section/Section'
import UnverifiedModal from './Unverified'
import { authMethods } from '../firebase/authMethods'
import { firebaseAuth } from 'provider/AuthProvider'
import Education from 'components/Forms/Education'
import EduEditForm from 'components/Forms/EduEdit'
import AddNewEduForm from 'components/Forms/AddNewEdu'
import SkillEditForm from 'components/Forms/SkillEdit'
import Skills from 'components/Forms/Skills'

export default () => {
  // Todo categorized states into object based on section
  const [isHighlightEditMode, setIsHighlightEditMode] = useState(false)
  const [isAboutEditMode, setIsAboutEditMode] = useState(false)
  const [isExpEditMode, setIsExpEditMode] = useState(false)
  const [editingExp, setEditingExp] = useState()
  const [showModal, setShowModal] = useState({ val: false, type: null })
  const [about, setAbout] = useState()
  const [workExperiences, setWorkExperiences] = useState()
  const [otherData, setOtherData] = useState()
  const [cvUrl, setCvUrl] = useState()
  const [isVerified, setIsVerified] = useState(false)
  const [edus, setEdus] = useState() //educations
  const [isEduEdit, setIsEduEdit] = useState(false)
  const [editingEdu, setEditingEdu] = useState()
  const { handleUpdateToken } = useContext(firebaseAuth)
  const [skills, setSkills] = useState({ isEdit: false, data: null })

  useEffect(() => {
    getData()
    getCv()
  }, [isHighlightEditMode])
  // useEffect(() => {
  //   if (workExperiences) {
  //   }
  //   console.log(edus)
  // }, [workExperiences, edus])

  const getCv = async () => {
    setCvUrl(await getUserFile('cv', 'pdf'))
  }

  const getData = async () => {
    const response = await getUserData()
    const {
      name,
      highlight,
      location,
      imgUrl,
      signInProvider,
      email_verified,
      about,
      workExperience,
      educations,
      skills,
    } = response
    const otherData = {
      name,
      highlight,
      location,
      imgUrl,
      signInProvider,
    }
    setAbout(about)
    setOtherData(otherData)
    setIsVerified(email_verified)
    if (educations) {
      setEdus(Object.values(educations))
    }
    if (workExperience) {
      setWorkExperiences(Object.values(workExperience))
    }
    if (skills) {
      const arrSkills = Object.keys(skills)
      setSkills({ data: [...arrSkills], isEdit: false })
    }
  }

  const handleRefresh = async () => {
    await handleUpdateToken()
    const { email_verified } = await getUserData()
    if (email_verified) {
      await setIsVerified(true);
      window.location.reload();
    }
  }

  const handleResend = () => {
    authMethods.resendEmailVerification()
  }

  const aboutSaveHandler = () => {
    try {
      updateUserData('about', about)
      setAbout(about)
    } catch (err) {
      alert('Error')
    } finally {
      setIsAboutEditMode(false)
    }
  }

  const selectSingleEdit = (idx, sectionName) => {
    if (workExperiences && sectionName === 'workExperience') {
      setEditingExp({ data: workExperiences[idx], idx: idx })
      setShowModal({ val: true, type: 'exp' })
    }
    if (edus && sectionName === 'educations') {
      setEditingEdu({ data: edus[idx], idx: idx })
      setShowModal({ val: true, type: 'edu' })
    }
  }

  const addNewExpToLocalState = (newData) => {
    if (workExperiences) {
      setWorkExperiences([...workExperiences, newData])
    } else {
      setWorkExperiences([newData])
    }
  }
  const updateExpInLocalState = (id, updatedData) => {
    const clone = [...workExperiences]
    clone[id] = { ...updatedData }
    setWorkExperiences(clone)
  }

  const addNewEduToLocalState = (newData) => {
    if (edus) {
      setEdus([...edus, newData])
    } else {
      setEdus([newData])
    }
  }

  const updateEduInLocalState = (id, updatedData) => {
    const clone = [...edus]
    clone[id] = { ...updatedData }
    setWorkExperiences(clone)
  }

  const addSkillsInLocalState = (newData) => {
    if (skills['data']) {
      setSkills({ ...skills, data: [...skills['data'], newData] })
    } else {
      setSkills({ ...skills, data: [newData] })
    }
  }

  const deleteSkillHandler = (idx) => {
    const cloneSkills = [...skills['data']]
    removeSkills(skills['data'][idx])
    cloneSkills.splice(idx, 1)
    setSkills({ ...skills, data: [...cloneSkills] })
  }

  // useEffect(() => {
  //   console.log(edus)
  // }, [edus])
  
  return (
    <SkeletonTheme color="#ffffff" highlightColor="#eeeeee">
      <Layout page="Dashboard">
        <UnverifiedModal
          show={
            typeof otherData !== 'undefined' &&
            otherData.signInProvider === 'password' &&
            !isVerified
          }
          resendHandler={handleResend}
          refreshHandler={handleRefresh}
        />
        <Highlight
          displayName={otherData ? otherData.name : <Skeleton />}
          highlightText={otherData ? otherData.highlight : <Skeleton />}
          locationText={otherData ? otherData.location : <Skeleton />}
          profileImage={otherData ? otherData.imgUrl : null}
          editMode={isHighlightEditMode}
          setEditMode={setIsHighlightEditMode}
          cvUrl={cvUrl}
        />
        <Section
          title="About"
          isEditedMode={isAboutEditMode}
          setIsEdit={() => {
            setIsAboutEditMode(true)
          }}
        >
          <Container>
          {about ? (
            isAboutEditMode ? (
              <AboutEditForm
                label="About"
                cancelHandler={() => {
                  setIsAboutEditMode(false)
                }}
                saveHandler={() => {
                  aboutSaveHandler()
                }}
                onChangeHandler={setAbout}
                formValue={about}
              />
            ) : (
              about
            )
          ) : (
            <Skeleton count={4} />
          )}
          </Container>
        </Section>
        <Section
          title="Work Experience"
          isEditedMode={isExpEditMode}
          setIsEdit={() => {
            setIsExpEditMode(true)
          }}
        >
          <Container>
            {workExperiences ?
              workExperiences.map((item, idx) => (
                <WorkExperience
                  data={item}
                  key={idx}
                  isExpEditMode={isExpEditMode}
                  setEdit={() => {
                    selectSingleEdit(idx, 'workExperience')
                  }}
                />
              ))
              :
              <p className="m-0">Tell me about your jobs...</p>
            }
            {isExpEditMode && (
              <>
                {editingExp && (
                  <WorkExperienceEditForm
                    showModal={showModal.val}
                    hideModal={() => {
                      setShowModal(false)
                      setEditingExp()
                    }}
                    editingExp={editingExp}
                    updateExpInLocalState={updateExpInLocalState}
                  />
                )}
                {!editingExp && showModal.type === 'exp' && (
                  <AddNewExperienceForm
                    showModal={showModal.val}
                    hideModal={() => {
                      setShowModal({ val: false })
                    }}
                    addNewExpToLocalState={addNewExpToLocalState}
                  />
                )}
                <EditCommandBtn
                  setShowModal={setShowModal}
                  type="exp"
                  setEditMode={() => {
                    setIsExpEditMode(false)
                  }}
                />
              </>
            )}
          </Container>
        </Section>
        <Section
          title="Education"
          isEditedMode={isEduEdit}
          setIsEdit={() => {
            setIsEduEdit(true)
          }}
        >
          <Container>
            {edus ?
              edus.map((item, idx) => (
                <Education
                  data={item}
                  key={idx}
                  isEduEditMode={isEduEdit}
                  setEdit={() => {
                    selectSingleEdit(idx, 'educations')
                  }}
                />
              )) : <p>Tell me about your educations...</p>}
            {isEduEdit && (
              <>
                {editingEdu && (
                  <EduEditForm
                    showModal={showModal.val}
                    hideModal={() => {
                      setShowModal({ val: false })
                      setEditingEdu()
                    }}
                    editingEdu={editingEdu}
                    updateEduInLocalState={updateEduInLocalState}
                  />
                )}
                {!editingEdu && showModal.type === 'edu' && (
                  <AddNewEduForm
                    showModal={showModal.val}
                    hideModal={() => {
                      setShowModal({ val: false })
                    }}
                    addNewEduToLocalState={addNewEduToLocalState}
                  />
                )}
                <EditCommandBtn
                  setShowModal={setShowModal}
                  type="edu"
                  setEditMode={() => {
                    setIsEduEdit(false)
                  }}
                />
              </>
            )}
          </Container>
        </Section>
        <Section
          title="Skills"
          isEditedMode={skills.isEdit}
          setIsEdit={() => setSkills({ ...skills, isEdit: true })}
        >
          <Container>
            {skills.isEdit && (
              <SkillEditForm
                addSkillsInLocalState={addSkillsInLocalState}
                showAddButton={skills.isEdit}
              />
            )}
            <Skills
              skills={skills}
              exitEdit={() => {
                setSkills({ ...skills, isEdit: false })
              }}
              deleteSkillHandler={deleteSkillHandler}
            />
          </Container>
        </Section>
      </Layout>
    </SkeletonTheme>
  )
}
