import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  RiArrowDropDownLine,
  RiArrowDropUpFill,
  RiAccountCircleLine,
} from 'react-icons/ri'
import { GrMail } from 'react-icons/gr'
import { FaSearch, FaHeart } from 'react-icons/fa'
import { MdNotificationsNone, MdLanguage, MdPermIdentity } from 'react-icons/md'
import { MdWork } from 'react-icons/md'
import { BiMessageDetail } from 'react-icons/bi'
import { useIsLargeScreen } from '../../utils/utils'
import { firebaseAuth } from 'provider/AuthProvider'
import './Nav.scss'
import { navlinks } from './constant'
import Hamburger from './Hamburger'

export default () => {
  const history = useHistory()
  const { token, handleSignout } = useContext(firebaseAuth)
  const [isMenuOpen, setisMenuOpen] = useState(false)
  const [langDropDownOpen, setLangDropDownOpen] = useState(false)
  const [accDropDownOpen, setAccDropDownOpen] = useState(false)
  const isLargeScreen = useIsLargeScreen()

  useEffect(() => {
    const sidebar = document.getElementById('sidebar')
    if (sidebar) sidebar.classList.toggle('show')
  }, [isMenuOpen])

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const navbar = document.getElementById('navbarscroll')
      if (navbar) {
        window.scrollY === 0
          ? navbar.classList.remove('pop-out')
          : navbar.classList.add('pop-out')
      }
    })
  }, [])

  const handleMenu = () => {
    setisMenuOpen(!isMenuOpen)
  }

  const getNavList = (
    <>
      {navlinks.map((link) => (
        <Link
          key={link.name}
          to={link.path}
          className="mr-3 px-1 py-2 hoverable font-weight-bold"
        >
          {link.name}
        </Link>
      ))}
      {/* <button
        className="btn mr-3 dropdown lang-dropdown"
        onClick={() => {
          setLangDropDownOpen(!langDropDownOpen);
        }}
      >
        <MdLanguage />
        {langDropDownOpen ? <RiArrowDropUpFill /> : <RiArrowDropDownLine />}
        {!isLargeScreen && langDropDownOpen && (
          <>
            <div className="dropdown-item">English</div>
            <div className="dropdown-item">Indonesia</div>
          </>
        )}
      </button> */}
    </>
  )

  const menusList = {
    mainMenuList: [
      {
        path: '/',
        icon: <MdPermIdentity size={25} />,
        name: 'Profile',
      },
      {
        path: '/jobs',
        icon: <MdWork size={25} />,
        name: 'Jobs',
      },
      // {
      //   path: "/message",
      //   icon: <BiMessageDetail />,
      //   name: "Message",
      // },
      // {
      //   path: "/saved_jobs",
      //   icon: <FaHeart />,
      //   name: "Saved Jobs",
      // },
    ],
    additionalMenuList: [
      // {
      //   path: "/mail",
      //   icon: <GrMail />,
      // },
      // {
      //   path: "/notifications",
      //   icon: <MdNotificationsNone />,
      // },
    ],
  }

  const Brand = (
    <Link to="/" className="h5 ml-2 hoverable font-weight-bold">
      TALENT POOL
    </Link>
  )

  const unAuthNav = (
    <nav id="navbarscroll">
      <div className="postition-relative w-100 h-100 px-3">
        <div className="d-flex align-items-center w-100 h-100">
          <div className="mr-auto">{Brand}</div>
          {isLargeScreen ? (
            <div className="d-flex align-items-center">{getNavList}</div>
          ) : (
            <Hamburger
              handleMenu={handleMenu}
              isMenuOpen={isMenuOpen}
              defaultOpen={isLargeScreen}
            />
          )}
        </div>
        {/* {langDropDownOpen && isLargeScreen && (
          <div className="menu language">
            <div className="dropdown-item">English</div>
            <div className="dropdown-item">Indonesia</div>
          </div>
        )} */}
      </div>
      {!isLargeScreen && isMenuOpen && (
        <div className="d-flex flex-column navlist-mobile">{getNavList}</div>
      )}
    </nav>
  )

  const additionalMenu = (
    <>
      {menusList.additionalMenuList.map((menu) => (
        <div key={menu.path} className="additional-menu">
          <Link to={menu.path} className="hoverable">
            <div className="additional-menu-icon">{menu.icon}</div>
          </Link>
        </div>
      ))}
      <div className="additional-menu-icon">
        <button
          className="btn hoverable"
          onClick={() => {
            setAccDropDownOpen(!accDropDownOpen)
            setLangDropDownOpen(false)
          }}
        >
          <RiAccountCircleLine size={25} />
          {accDropDownOpen ? <RiArrowDropUpFill /> : <RiArrowDropDownLine />}
          {accDropDownOpen && (
            <div className="menu account rounded bg-white shadow">
              <div
                className="dropdown-item font-weight-bold"
                onClick={async () => {
                  handleSignout();
                  // history.push('/signin')
                  // window.location.reload()
                }}
              >
                Log Out
              </div>
              {/* <div className="dropdown-item">Del Acc</div> */}
            </div>
          )}
        </button>
      </div>
      {/* <div className="additional-menu-icon">
        <button
          className="btn"
          onClick={() => {
            setLangDropDownOpen(!langDropDownOpen);
            setAccDropDownOpen(false);
          }}
        >
          <MdLanguage />
          {accDropDownOpen ? <RiArrowDropUpFill /> : <RiArrowDropDownLine />}
          {langDropDownOpen && (
            <div className="menu language">
              <div className="dropdown-item">English</div>
              <div className="dropdown-item">Indonesia</div>
            </div>
          )}
        </button>
      </div> */}
    </>
  )

  const mainMenu = (
    <div className="mt-4">
      {menusList.mainMenuList.map((menu) => (
        <div key={menu.name} style={{ minHeight: '40px' }} className="pb-4">
          <Link to={menu.path} className="d-block w-100 text-center hoverable">
            <div className="w-100 d-flex text-center main-menu my-2">
              {menu.icon}
            </div>
            {menu.name}
          </Link>
        </div>
      ))}
    </div>
  )

  const authNav = (
    <>
      <nav id="navbarscroll">
        <div className="w-100 h-100 d-flex align-items-center">
          <div className="row  w-100  mx-4">
            <div className="col col-md-2 d-flex justify-content-start align-items-center">
              <div style={{ marginRight: '2rem' }}>
                <Hamburger
                  handleMenu={handleMenu}
                  isMenuOpen={isMenuOpen}
                  defaultOpen={isLargeScreen}
                />
              </div>
              <div>{Brand}</div>
            </div>
            {/* <div className="col col-md-6 d-flex align-items-center justify-content-end">
              <input className="search-bar" />
              <button className="btn" id="search-btn">
                <FaSearch />
              </button>
            </div> */}
            {isLargeScreen && (
              <div className="col col-md-2 ml-auto">
                <div
                  className="row justify-content-end px-3"
                  style={{ flexWrap: 'noWrap' }}
                >
                  {additionalMenu}
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <aside id="sidebar" className={isLargeScreen ? '' : 'show'}>
        <div className="sidebar-content">
          <div>{mainMenu}</div>
          {!isLargeScreen && (
            <div className="mobile-additional-menu">{additionalMenu}</div>
          )}
        </div>
      </aside>
    </>
  )

  return (
    <>
      {token ? authNav : unAuthNav}
      <div style={{ height: '70px' }} />
    </>
  )
}
