import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import AuthorizedRoute from "router/AuthorizedRoute";
import UnauthorizedRoute from "router/UnauthorizedRoute";
import SignIn from "page/SignIn";
import SignUp from "page/SignUp";
import { firebaseAuth } from "provider/AuthProvider";

function App() {
  const { token } = useContext(firebaseAuth);
  return (
    <Switch>
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route path="/" component={token ? AuthorizedRoute : UnauthorizedRoute} />
    </Switch>
  );
}

export default App;
