import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Job from "components/Jobs";
import { getJobsData } from "firebase/jobsHandler";

export default () => {
  const [jobsList, setJobsList] = useState();

  const getJobs = async () => {
    setJobsList(await getJobsData());    
  }

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <Layout page="Jobs">
        {jobsList ? 
        <Container className="my-5 px-2">
        <Row className="px-4">
          <span className="mr-auto">
            <h3 className="font-weight-bold">
              Available Jobs
            </h3>
          </span>
          {/* <span>{LandingContent.rightTitle}</span> */}
        </Row>
        {jobsList.map((job) => (
          job.closed ? null
          :
          <div key={job.id} className="py-2">
            <Job.Card data={job} isLike={false} />
          </div>
        ))}
        {jobsList.map((job) => (
          job.closed && job.company !== "Testing" ? 
          <div key={job.id} className="py-2">
            <Job.Card data={job} isLike={false} closed={job.closed} />
          </div>
          :
          null
        ))}
        </Container>
        : 
        <Container style={{height: "75vh"}} className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" className="my-auto text-primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      }
    </Layout>
  );
};
