import React from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'

const EditForm = ({
  cancelHandler,
  onChangeHandler,
  saveHandler,
  formValue,
  label,
}) => {
  return (
    <>
      <Form.Group>
        <FormControl
          as="textarea"
          value={formValue}
          type="text"
          rows="5"
          onChange={(e) => onChangeHandler(e.target.value)}
        />
      </Form.Group>
      <Button className="float-right btn-md btn-primary" onClick={saveHandler}>
        Save
      </Button>
      <Button
        className="float-right mr-2 btn-md btn-danger"
        onClick={cancelHandler}
      >
        Cancel
      </Button>
    </>
  )
}

export default EditForm
