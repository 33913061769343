import React from 'react';
import { Col, Row } from 'react-bootstrap';
import "./styles/explore.scss";

const Explore = () => {
  const exploreItems = [
    {
      src: require("../../assets/page_homepage/s_explore/explore1.png"),
      description: "Accounting & Finance"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore2.png"),
      description: "Human Resources"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore3.png"),
      description: "General Services"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore4.png"),
      description: "IT & Software"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore5.png"),
      description: "Management & Consultancy"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore6.png"),
      description: "Sales & Marketing"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore7.png"),
      description: "Administration & Coordination"
    },
    {
      src: require("../../assets/page_homepage/s_explore/explore8.png"),
      description: "Media & Creatives"
    },
  ];


  return (
    <section className="homepage-explore p-5">
      <div className="explore-title">
        <h3>Explore</h3>
        <p>Discover jobs by categories</p>
      </div>
      <Row className="explore-images">
        {exploreItems.map((item, key) => {
          return (
            <Col xs={6} lg={3} key={key}>
              <img src={item.src} alt={item.description}/>
              <p>{item.description}</p>
            </Col>
          )
        })}
      </Row>
    </section>
  )
}

export default Explore;