const SEO_DATA = {
  title: 'SRE Career',
  url: 'career.srenasional.com',
  img: '/logo192.png',
  keywords: [
    'sreitb',
    'sre itb', 'sre ugm', 'sre ui', 
    'sre unsri', 'sre untan', 'sre tel-u',
    'sre its', 'sre unhas', 'sre undip',
    'sre uns', 'sre itera', 'sre ub',
    'sre',
    'society of renewable energy',
    'society of renewable energy itb',
    'SRE ITB',
    'renewable energy',
    'sre nasional',
    'sre indonesia',
  ],
}

export default SEO_DATA;