import { addNewSkills } from 'firebase/userHandler'
import React, { useState } from 'react'
import { Button, FormGroup, InputGroup } from 'react-bootstrap'
import FormControl from 'react-bootstrap/FormControl'

const SkillEdit = ({ addSkillsInLocalState }) => {
  const [formValue, setFormValue] = useState('')
  const onChangeHandler = (e) => {
    setFormValue(e.target.value)
  }
  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      addSkillsInLocalState(formValue)
      await addNewSkills(formValue)
    } catch (err) {
      alert('Error')
    } finally {
      setFormValue('')
    }
  }
  return (
    <>
      <form onSubmit={submitHandler}>
        <FormGroup>
          <InputGroup>
            <FormControl
              value={formValue}
              type="text"
              placeholder="eg: JavaScript, Python"
              onChange={onChangeHandler}
              required
            />
            <InputGroup.Append>
              <Button className="btn-md btn-primary" type="submit">
                Add
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </FormGroup>
      </form>
    </>
  )
}

export default SkillEdit
