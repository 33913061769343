import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from 'page/Dashboard'
import _404 from 'page/404.js'
import Job from 'page/Job'
import Unverified from 'page/Unverified'

const AuthorizedRoute = ({ match }) => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/jobs" component={Job} />
    <Route path={match.url} component={_404} />
  </Switch>
)

export default AuthorizedRoute
