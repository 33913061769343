import firebaseconfig from './firebaseIndex';
import firebase from 'firebase';
import { changeToUrl } from 'utils/utils';
import { getUserData, updateUserData } from './userHandler';
import jwt_decode from 'jwt-decode';

export const getJobsData = async () => {
  let result;
  await firebase.database().ref(`jobs`)
    .once('value', snapshot => {
      result = snapshot.val();
    })
  return result;
}

export const getJobImg = async (companyName) => {
  let result = null;
  await firebase.storage().ref(`jobs/images/${companyName}.png`)
    .getDownloadURL()
    .then(url => {
      result = url;
    });
  return result;
}

export const sendApplication = async (jobId, jobTitle, jobCompany, motivationLetter, jobType) => {
  let result;
  const titleUrl = changeToUrl(`${jobTitle} - ${jobCompany}`);
  const userData = await getUserData();
  const decoded = jwt_decode(localStorage.getItem('token'))
  await firebase
    .database()
    .ref(`applications/${titleUrl}/${decoded.user_id}`)
    .update({
      name: userData.name, 
      motivationLetter,
      cv: userData.cvUrl,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      })
    .then(() => {
      updateUserData('appliedJobId', userData.appliedJobId ? [...userData.appliedJobId, jobId] : [jobId])
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: userData.name,
          jobName: jobTitle,
          jobCompany: jobCompany,
          jobType: jobType,
          to: userData.email
        }),
      };
      fetch(
        'https://us-central1-sre-website-a43e8.cloudfunctions.net/applyJobTalentPool',
        requestOptions
      ).then(() => result = 'success');
    })
    .catch(() => result = 'failed');
    
  return result;
}