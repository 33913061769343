import React from "react";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import SEO from "components/SEO";

const Layout = ({ page, children }) => (
  <>
    <SEO page={page} />
    <Navigation />
      <div className="app">{children}</div>
    <Footer />
  </>
);

export default Layout;
