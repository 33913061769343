import firebaseconfig from './firebaseIndex';
import firebase from 'firebase';
import jwt_decode from 'jwt-decode';

export const uploadFile = async (file, type) => {
  let result = null;
  const decoded = jwt_decode(localStorage.getItem('token'));
  const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
  await firebase.storage().ref(`users/${decoded.user_id}/${type}/${type+"-"+decoded.name+fileExtension}`)
  .put(file)
  .then(snapshot => {
    result = snapshot.state; //return "success"
  });
  return result;
}

export const getUserFile = async (type, ext) => {
  let url = null;
  const decoded = jwt_decode(localStorage.getItem('token'));
  await firebase.storage()
    .ref(`users/${decoded.user_id}/${type}/${type+"-"+decoded.name}.${ext}`)
    .getDownloadURL()
    .then(fileUrl => url = fileUrl)
    .catch(err => console.log(err));
  return url;
}