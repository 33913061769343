import firebaseconfig from './firebaseIndex'
import firebase from 'firebase'
import jwt_decode from 'jwt-decode'

export const getUserData = async () => {
  const decoded = jwt_decode(localStorage.getItem('token'))
  // console.log(decoded)
  let result = {
    name: decoded.name,
    email: decoded.email,
    signInProvider: decoded.firebase.sign_in_provider,
    email_verified: decoded.email_verified,
  }
  await firebase
    .database()
    .ref(`users/${decoded.user_id}`)
    .once('value')
    .then((snapshot) => {
      const data = snapshot.val()
      result = {
        ...result,
        ...data,
      }
    })
  return result
}

export const updateDisplayName = async (newName) => {
  firebase
    .auth()
    .currentUser.updateProfile({ displayName: newName })
}

export const updateUserData = async (attribute, val) => {
  const decoded = jwt_decode(localStorage.getItem('token'))
  // Atau trycathnya ntr di frontend aja (?)
  try {
    await firebase
      .database()
      .ref(`users/${decoded.user_id}`)
      .update({ [attribute]: val })
  } catch (err) {
    // console.log(err)
  }
}

export const addNewExp = async (newExp) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  let lastIdx
  await firebase
    .database()
    .ref(`users/${user_id}/workExperience`)
    .once('value')
    .then((snapshot) => {
      // if workExp not found return 0
      lastIdx = snapshot.numChildren()
    })
  await firebase
    .database()
    .ref(`users/${user_id}/workExperience`)
    .child(lastIdx + 1)
    .set({ ...newExp })
}
export const updateExp = async (id, updatedData) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  await firebase
    .database()
    .ref(`users/${user_id}/workExperience`)
    .child(id)
    .update({ ...updatedData })
}

// @Todo - Bisa direuse AddNewExp kalo ntr mau refactor
export const addNewEdu = async (newEdu) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  let lastIdx
  await firebase
    .database()
    .ref(`users/${user_id}/educations`)
    .once('value')
    .then((snapshot) => {
      lastIdx = snapshot.numChildren()
    })
  await firebase
    .database()
    .ref(`users/${user_id}/educations`)
    .child(lastIdx + 1)
    .set({ ...newEdu })
}
export const updateEdu = async (id, updatedData) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  await firebase
    .database()
    .ref(`users/${user_id}/educations`)
    .child(id)
    .update({ ...updatedData })
}

export const addNewSkills = async (newSkill) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  await firebase
    .database()
    .ref(`users/${user_id}/skills`)
    .child(newSkill)
    .set(0)
}

export const removeSkills = async (skillName) => {
  const { user_id } = jwt_decode(localStorage.getItem('token'))
  await firebase
    .database()
    .ref(`users/${user_id}/skills`)
    .child(skillName)
    .remove()
}
