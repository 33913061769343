import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useFormInput } from 'utils/utils';
import { firebaseAuth } from 'provider/AuthProvider';
import { updateUserData } from 'firebase/userHandler';

export default ({
  displayName,
  highlightText,
  locationText,
  setEditMode
}) => {
  const { handleUpdateToken } = useContext(firebaseAuth)
  const highlight = useFormInput(highlightText);
  const location = useFormInput(locationText);

  const submitForm = async () => {
    if ( highlight.value && location.value) {
      await updateUserData('highlight', highlight.value);
      await updateUserData('location', location.value);
      await handleUpdateToken();
      setEditMode(false);
    }
  }

  return (
    <Container className="mx-xs-2 mx-md-auto">
      <Form>
        <h1 className="font-weight-bold">{displayName}</h1>
        <Form.Control className="mb-1" type="text" placeholder="Your Highlighted Job" value={highlight.value} onChange={highlight.onChange} />
        <Form.Control size="sm" type="text" placeholder="Your Location" value={location.value} onChange={location.onChange} />
        <Button variant="warning" className="mt-2 ml-2 float-right" onClick={() => submitForm()}>
          Save
        </Button>
        <Button variant="danger" className="mt-2 ml-2 float-right" onClick={() => setEditMode(false)}>
          Cancel
        </Button>
      </Form>
    </Container>
  );
}