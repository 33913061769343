import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Jumbotron,
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
} from 'react-bootstrap'
import { FaFacebookSquare } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import Layout from 'components/Layout'
import { firebaseAuth } from 'provider/AuthProvider'

export default ({ history }) => {
  const { handleSignin, inputs, setInputs, errors, token } = useContext(
    firebaseAuth,
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSignin()
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }

  return token ? (
    <Redirect push to="/" />
  ) : (
    <Layout page="Sign In">
      <Jumbotron
        fluid
        className="my-0 background-signin"
        style={{ minHeight: '75vh' }}
      >
        <Container fluid>
          <Row>
            <Col
              xs={12}
              md={8}
              className="text-white text-center d-flex justify-content-center align-items-center"
            >
              <div className="container">
                <h1 className="font-weight-bold">Get Your Dream Career Now</h1>
                <p>Join millions success stories</p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <Card className="shadow card-signup">
                <Card.Body>
                  <Card.Title as="h2" className="text-center font-weight-bold">
                    Sign In
                  </Card.Title>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={inputs.password}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Button variant="primary btn-block my-4" type="submit">
                      Sign In
                    </Button>
                    {errors.length > 0
                      ? errors.map((error) => (
                          <p key={error} style={{ color: 'red' }}>
                            {error}
                          </p>
                        ))
                      : null}
                    <hr />
                  </Form>
                  {/* <Button variant="facebook btn-block mb-3 mt-4" type="submit">
                    <div className="d-flex align-items-center justify-content-center">
                      <FaFacebookSquare size={22} className="mr-3"/> Sign In with Facebook
                    </div>
                  </Button> */}
                  {/* <Button variant="outline-dark btn-block bordered my-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <FcGoogle size={22} className="mr-3"/> Sign In with Google
                    </div>
                  </Button> */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <p>
                      Don't have an account?{' '}
                      <Link to="/signup" className="text-primary">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </Layout>
  )
}
